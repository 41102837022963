.cursor-pointer {
  cursor: pointer;
}
.font_size_large {
  font-size: large;
}
.image_handler {
  width: 120px;
  height: 120px;
  padding: 1px;
}
