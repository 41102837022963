.cursor-pointer {
  cursor: pointer;
}
.font_size_large {
  font-size: large;
}
.image_handler {
  width: 120px;
  height: 120px;
  padding: 1px;
}

.deleteProductConfirm {
  .modal-content {
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .modal-header {
    background-color: rgb(193, 193, 245);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
  }

  .modal-body {
    font-size: 1rem;
    padding: 20px;
  }

  .modal-footer {
    border-top: none;
    padding: 5px;
  }
}

.Toastify__close-button > svg {
  height: 24px;
  width: 24px;
}
