.cursor-pointer {
  cursor: pointer;
}
.font_size_large {
  font-size: large;
}
.image_handler {
  width: 120px;
  height: 120px;
  padding: 1px;
}


/* .edit-offer-page-content {
  height: calc(100vh - 70px);
} */

.search_result_area table {
  margin: 0;
}
.search_result_container.card-body {
  padding: 4px 18px;
}

.search_result_area .table-responsive tbody tr td {
  padding: 2px 0 0;
}

.search_result_container img {
  height: 95%;
  width: 80%;
}

#newEditOfferLeftSide .input-group .btn.submit_new {
  position: relative;
  z-index: 0;
}
/* .search_result_area label {
  font-weight: bold;
} */

.Toastify__close-button > svg {
  height: 24px;
  width: 24px;
}

/* error-message {
  color: #ed7476;
  font-size: 14px;
  line-height: 30px;
  margin: 50px auto;
  display: flex;
  justify-content: start;
  align-items: start;
} */

.error-message {
  color: #ed7476;
  font-size: 14px;
  line-height: 30px;
  margin: 25px auto;
  display: flex;
  justify-content: start;
  align-items: start;
}
